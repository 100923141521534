
const routes = [
  {
    path: '/',
    component: () => import('../src/components/Home.vue'),
  },

  {
    path: '/users',
    component: () => import('../src/components/Users.vue'),
  },


  // Always leave this as last one,
  // but you can also remove it

]

export default routes
