import Vue from 'vue'
import './style.css'
import App from './App.vue'
import VueRouter from 'vue-router'

import axios from 'axios'
import routes from './routes' // Asegúrate de que la ruta sea correcta


Vue.use(VueRouter)


Vue.use(axios)


const router = new VueRouter({
    mode: 'history',
    routes
})

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')